import React, { useEffect, useRef, useState } from "react";
import PageBanner from "../Components/PageBanner";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuthContext } from "../Contexts/AuthContext";
import { Link, Navigate } from "react-router-dom";
import { AiFillBank } from "react-icons/ai";
import paypal from "../assets/paypal.png";
import { toast } from "react-toastify"
import { PiMoneyFill } from "react-icons/pi";

const OrderHistory = ({ }) => {
  const messageRef = useRef();
  const { userdata } = useAuthContext();
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState("");
  const [ploading, setPloading] = useState(false);
  const [show, setShow] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [order, setorder] = useState({});


  const FetchOrders = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/orders/userorders",
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      setOrders(data.orders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchOrders();
  }, []);

  const Open = async (elem) => {
    setorder(elem);

    if (elem.link) {
      const id = elem.link.match(/\/([^/]+)$/)[1];
      const { data } = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/pay/status",
        {
          id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );

      setStatus(data.status);
    }

    setShow(true);
  };

  const CheckColor = (elem) => {
    let color = "";
    if (
      elem.toUpperCase() === "PENDIENTE" ||
      elem.toUpperCase() === "RECOGIENDO" ||
      elem.toUpperCase() === "ANULADA"
    ) {
      color = "red";
    } else if (elem.toUpperCase() === "ADUANAS") {
      color = "yellow";
    } else if (
      elem.toUpperCase() === "ENTREGADO" ||
      elem.toUpperCase() === "DISPONIBLE" ||
      elem.toUpperCase() === "COMPLETADA"
    ) {
      color = "green";
    } else {
      color = "blue";
    }

    return color;
  };

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);


  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
    }
  }, [showBank])

  const PaypalFees = (amount) => {
    const fees =
      (amount
        ?.replaceAll(".", "dd")
        .replaceAll(",", "cc")
        .replaceAll("dd", "")
        .replaceAll("cc", ".")
        .replaceAll("€", "") *
        3.5) /
      100;
    let total = (
      Number(
        amount
          ?.replaceAll(".", "dd")
          .replaceAll(",", "cc")
          .replaceAll("dd", "")
          .replaceAll("cc", ".")
          .replaceAll("€", "")
      ) - Number(fees)
    ).toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return amount ? total : "";
  };


  const CardFees = (amount) => {
    const fees =
      (amount
        ?.replaceAll(".", "dd")
        .replaceAll(",", "cc")
        .replaceAll("dd", "")
        .replaceAll("cc", ".")
        .replaceAll("€", "") *
        1.95) /
      100;
    let total = (
      Number(
        amount
          ?.replaceAll(".", "dd")
          .replaceAll(",", "cc")
          .replaceAll("dd", "")
          .replaceAll("cc", ".")
          .replaceAll("€", "")
      ) - Number(fees)
    ).toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return amount ? total : "";
  };

  const HandlePaid = async () => {
    setPloading(true)
    await axios.post(
      process.env.REACT_APP_BASE_URL + "/api/orders/paid",
      {
        name: userdata?.name,
        email: userdata.email,
        orderid: order.orderId,
        id: userdata.uid
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );
    FetchOrders()
    toast.success(
      "Tu aviso ha sido enviado.", {
      position: "top-center",
      theme: "dark"
    }
    );
    setShow(false)
    setPloading(false)
  }


  if (!userdata) return <Navigate to={"/"} />;

  return (
    <>
      <PageBanner title={"Historial de Envíos"} />
      <div className="Orderhistory table-responsive canvas">
        <table className="table  mt-10 bg-white rounded shadow">
          <div className="filters"></div>
          <thead className="text-white bg-dark">
            <tr>
              <th scope="col">Orden</th>
              {window.matchMedia("(min-width: 600px)").matches && (
                <>
                  <th scope="col">Fecha</th>
                  <th scope="col">Beneficiario</th>
                </>
              )}
              <th scope="col">Estado</th>
              <th>Ver</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((elem) => {
              return (
                <tr key={elem.orderId}>
                  <td className="notranslate">TV{elem.orderId}</td>
                  {window.matchMedia("(min-width: 600px)").matches && (
                    <>
                      <td>{elem.date}</td>
                      <td>{elem.bname}</td>
                    </>
                  )}
                  <td>
                    <p
                      className={`badge rounded-pill py-2 px-3 ${CheckColor(
                        elem.status
                      )}`}
                    >
                      {elem.status.toUpperCase()}
                    </p>
                  </td>
                  <td className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary w-auto mt-0 fs-6 py-1 me-2"
                      onClick={() => Open(elem)}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Detalles
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {show && (
          <div
            className="modal_bg d-flex justify-content-center 
          align-items-center"
          >
            <div
              className="modal"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Detalles # <span className="notranslate">TV{order.orderId}</span>
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShow(false);
                        setStatus("");
                        setShowBank(false);
                      }}
                    ></button>
                  </div>
                  <div className="modal-body order_modal">
                    <div className="d-flex flex-wrap mt-1">
                      <p
                        className="mt-1 notranslate"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        Orden : <span className="notranslate">TV{order.orderId}</span>
                      </p>
                      {order.country?.toLowerCase() === "venezuela" ?
                        <p
                          className=" mt-1"
                          style={{
                            width: window.matchMedia("(max-width: 650px)").matches
                              ? "100%"
                              : "50%",
                          }}
                        >
                          Entidad Bancaria : {order.bbank}
                        </p>

                        :

                        <p
                          className=" mt-1"
                          style={{
                            width: window.matchMedia("(max-width: 650px)").matches
                              ? "100%"
                              : "50%",
                          }}
                        >
                          País : {order?.country}
                        </p>

                      }

                      <p
                        className=" mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        Fecha : {order.date}
                      </p>


                      <p
                        className="mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        Cantidad a enviar (EUR) : {order.amount}
                      </p>

                      <p
                        className="mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        Estado :  <span
                          className={`badge rounded-pill py-1 px-3 ms-1 ${CheckColor(
                            order.status
                          )}`}
                        >
                          {order.status}{" "}
                        </span>
                      </p>



                      <p
                        className=" mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        Total a pagar (EUR) : {order.totalAmount}
                      </p>



                      <p
                        className=" mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        {order.country?.toLowerCase() === "venezuela" ? " Nombre y Apellido Beneficiario" : "Nombres y Apellidos COMPLETOS del Beneficiario"} : {order.bname}
                      </p>

                      <p
                        className=" mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        Recibe ({order.NOM}) : {order.gets}
                      </p>

                      {order.country?.toLowerCase() === "venezuela" && <p
                        className="mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        Cédula : {order.cedula}
                      </p>
                      }



                      <p
                        className=" mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        Forma de Pago : {order?.ptype ? order?.ptype : "Transferencia bancaria"}
                      </p>

                      <p
                        className="mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        {order.country?.toLowerCase() === "venezuela" ? "Numero de Cuenta Beneficiario " : "Teléfono Beneficiario"}  : {order.bAccnumber}
                      </p>

                      {order?.remarks && <p
                        className=" mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        Observaciones especiales : {order?.remarks}
                      </p>
                      }

                    </div>

                    {order.status.toLowerCase() !== "recibido" &&
                      order.status.toLowerCase() !== "completada" ? (
                      <div className="d-flex mt-1">
                        <button
                          className={`continue w-auto py-2`}
                          onClick={() => {
                            setShowBank(true)
                            var scrollableDiv = document.getElementById("exampleModal");
                            scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
                          }}
                        >
                          Datos Pago
                        </button>
                        <button
                          type="button"
                          className={`${order.paid === false && order.status.toLowerCase() !== "recibido" ? "continue" : "continue bg-secondary"} text-white w-auto ms-3 py-2`}
                          disabled={!ploading && order.paid === false && order.status.toLowerCase() !== "recibido" ? false : true}
                          onClick={() => {
                            HandlePaid()
                          }}
                        >
                          {ploading ? "Enviando" : "He pagado"}
                        </button>
                      </div>

                    ) : (
                      <button
                        className="mt-1 btn btn-success py-2 px-3"
                        disabled={true}
                      >
                        PAGADA
                      </button>

                    )}

                    {showBank && (
                      <>
                        <div className="d-flex flex-wrap mt-1">
                          {order.ptype !== "Paypal" && order.ptype !== "Pago con Tarjeta" && <> <div className="mt-3 me-4">
                            <div className="d-flex align-items-center mb-2">
                              <div className="bg-danger pay_icon">
                                <AiFillBank size={25} color="white" />
                              </div>
                              <p className="ms-3 mb-0">
                                Transferencia bancaria
                              </p>
                            </div>
                            <p>TITULAR: TRANSFERVEN ENVíOS</p>
                            <p>BANCO: BBVA</p>
                            <p>IBAN: ES91 0182 0761 2002 0179 7309</p>
                            <p>CONCEPTO: "TV{order.orderId}"</p>
                            <p>
                              Factura:
                              <span className="fw-bold">
                                € {order.totalAmount?.replace("€", "")}{" "}
                              </span>
                            </p>
                          </div>
                            <div className="mt-3 me-4">
                              <div className="align-items-center mb-2" style={{ visibility: "hidden", display: window.matchMedia("(max-width: 550px)").matches ? "none" : "flex" }}>
                                <div className="bg-danger pay_icon">
                                  <AiFillBank size={25} color="white" />
                                </div>
                                <p className="ms-3 mb-0">
                                  Transferencia bancaria
                                </p>
                              </div>
                              <p>TITULAR: TRANSFERVEN ENVíOS</p>
                              <p>BANCO: SANTANDER</p>
                              <p>IBAN: ES81 0049 0153 4128 1308 9268</p>
                              <p>CONCEPTO: "TV{order.orderId}"</p>
                              <p>
                                Factura:
                                <span className="fw-bold">
                                  € {order.totalAmount?.replace("€", "")}{" "}
                                </span>
                              </p>
                            </div>
                          </>
                          }
                          {order.ptype === "Pago con Tarjeta" && <div className="mt-3 me-4">
                            <div className="d-flex align-items-center mb-2" >
                              <div className="bg-danger pay_icon">
                                <PiMoneyFill size={25} color="white" />
                              </div>
                              <p className="ms-3 mb-0">
                                Tarjeta Débito / Crédito
                              </p>
                            </div>
                            <p>TITULAR: EURO DIGITAL MULTISERVICES</p>
                            <p>Se aplica 1.95% comisión</p>
                            <p>CONCEPTO: "TV{order.orderId}"</p>
                            <p className="mb-2">
                              Factura:
                              <span className="fw-bold">
                                € {(order.totalAmount)}
                              </span>
                            </p>
                            <a href="https://wa.me/34620818181?text=Hola, solicito enlace de pago con Tarjeta para Orden N." target="_blank" className="continue px-3 py-1 d-inline">Solicitar Enlace</a>
                          </div>
                          }
                          {order.ptype === "Paypal" && <div
                            className="mt-md-3 mt-0"
                          >
                            <div className="d-flex align-items-center mb-1">
                              <img src={paypal} height={60} />
                            </div>
                            <p>Se aplica 3.5% comisión</p>
                            <p>info.es@transferven.com</p>
                            <p>CONCEPTO: "TV{order.orderId}"</p>
                            <p>
                              Factura:{" "}
                              <span className="fw-bold">
                                € {(order.totalAmount)}
                              </span>
                            </p>
                          </div>
                          }
                        </div>
                        <p className="mt-3 small text-danger">IMPORTANTE: Evita conceptos tales como "Ayuda", "Manutención", "Donación". Colocar EXCLUSIVAMENTE el concepto que se indica en los detalles de tu pago para evitar atrasos.</p>
                      </>
                    )}
                    <div ref={messageRef}></div>


                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setShow(false);
                        setStatus("");
                        setShowBank(false);
                      }}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div >
    </>
  );
};

export default (OrderHistory);
